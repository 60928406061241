<template>
  <PageWithLayout>
    <Search
      :searchDataList.sync="viewModel.searchData.searchDataList"
      :searchParams.sync="viewModel.searchParams"
      @onSearch="viewModel.onSearch()"
    />
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :paginationData="viewModel.paginationData"
      :dataList="viewModel.dataList"
      @onClickRow="(rowId, rowData) => viewModel.onClickRow(rowId, rowData)"
      @onSearch="viewModel.onSearch()"
    >
      <template v-slot:headRight>
        <Button
          btnSize="small"
          btnStyle="primary"
          text="등록하기"
          routeName="CONTENT_TEENS_ECONOMIC_NEWS_REGISTER"/>
      </template>
    </Board>
  </PageWithLayout>
</template>
<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// viewModel
import TeensEconomicNewsListViewModel from '@/views/content/teensEconomicNews/viewModel/TeensEconomicNewsListViewModel';

export default {
  name: 'TeensEconomicNewsList',
  components: {
    PageWithLayout,
    Search,
    Board,
    Button
  },
  data() {
    return {
      viewModel: new TeensEconomicNewsListViewModel(),
    };
  },
  computed: {
    searchData() {
      return this.viewModel.searchData[this.viewModel.tabData.value];
    },
  },
  mounted(){
    this.viewModel.init();
  },
};
</script>

<style scoped></style>
