import { GET_REQUEST } from '@/services/http';

import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';
import router from '@/router';

export default class TeensEconomicNewsListViewModel {
  constructor() {
    this.searchData = {
      searched: false,
      searchedValue: '',
      hasData: true,
      searchDataList: [
        {
          title: '키워드',
          type: 'Input',
          grid: 100,
          isFull: true,
          value: '',
        },
      ],
      responseDate: '',
    };
    (this.dataList = []),
      (this.boardData = {
        title: '틴즈 경제 뉴스 목록',
        drawDataList: [
          {
            title: 'No.',
            width: '60px',
            value: 'id',
            isSorting: true,
          },
          {
            title: '이미지',
            width: '50px',
            value: 'share_thumbnail',
            img: {
              width: '36px',
              height: '36px',
              position: {
                x: 'center',
                y: '0',
              },
              size: 'cover',
            },
          },
          {
            title: '비디오<br>포스터',
            width: '50px',
            value: 'video_thumbnail',
            img: {
              width: '36px',
              height: '36px',
              position: {
                x: 'center',
                y: '0',
              },
              size: 'cover',
            },
          },
          {
            title: '비디오',
            width: '50px',
            value: 'isVideoUrl',
            filter: { name: 'convertIdToText', value: 'common_boolean_ox' },
          },
          {
            title: '제목',
            width: '',
            value: 'title',
            isAlignLeft: true,
          },
          {
            title: '설명',
            width: '',
            value: 'description',
            isAlignLeft: true,
          },
          {
            title: '포스트<br/>타입',
            width: '70px',
            value: 'post_type',
            filter: { name: 'convertIdToText', value: 'post_type' },
            badge: {
              badgeColorFilter: {
                name: 'convertIdToColor',
                dataListName: 'post_type',
              },
              badgeFill: false,
            },
          },
          {
            title: '링크명',
            width: '200px',
            value: 'link',
            isAlignLeft: true,
            isBreakAll: true,
          },
          {
            title: '상태',
            width: '80px',
            value: 'status',
            filter: {
              name: 'convertIdToText',
              value: 'blog_status',
            },
            badge: {
              badgeColorFilter: {
                name: 'convertIdToColor',
                dataListName: 'blog_status',
              },
              badgeFill: false,
            },
          },
          {
            title: '댓글유무',
            width: '62px',
            value: 'use_comments',
            filter: {
              name: 'convertIdToText',
              value: 'blog_comment',
            },
            emptyValueText: '-',
          },
          {
            title:'조회수',
            width:'80px',
            value:'view_count',
            isAlignRight: true,
            emptyValueText:'-'
          },
          {
            title: '최종수정일시',
            width: '160px',
            value: 'updated_at',
            filter: { name: 'convertDateFormat', value: 'YYYY-MM-DD HH:mm:ss' },
            isMobileTitle: true,
            isAlignLeft: true,
          },
        ],
        option: {
          isTotal: true,
          isSize: true,
        },
      });
    this.searchParams = {
      page: 0,
      pageSize: 10,
      direction: 'DESC',
    };
    this.paginationData = {
      totalCount: 0,
      totalPage: 0,
    };
  }

  init() {
    this.getData();
  }

  onClickRow(rowId, rowData){
    router.push({
      name: 'CONTENT_TEENS_ECONOMIC_NEWS_DETAIL',
      params: { link: rowData.link },
      query : {
        pageSize : this.searchParams.pageSize,
        page : this.searchParams.page,
      }
    });
  }

  onSearch(){
    const searchValue = this.searchData.searchDataList[0].value;
    if(searchValue){
      this.getDataByKeyword(searchValue);
    }else{
      this.getData();
    }
  }
  
  // [API] 전체 목록
  getData() {
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.CONTENT_TEENS_ECONOMIC_NEWS_LIST}${query}`;
    GET_REQUEST(path).then(
      success => {
        const resultData = success.data;
        const dataList = resultData.body;
        this.dataList = dataList.map(item => {
          item.isVideoUrl = Boolean(item.video_url);
          return item;
        });
        this.paginationData.totalCount = resultData.pagination.total_elements;
        this.paginationData.totalPage = resultData.pagination.total_page;
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
  // [API] 키워드 검색
  getDataByKeyword(keyword) {
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.CONTENT_TEENS_ECONOMIC_NEWS_SEARCH.format(
      keyword,
    )}${query}`;
    console.log(path)
    GET_REQUEST(path).then(
      success => {
        const resultData = success.data;
        this.dataList = resultData.body;
        this.paginationData.totalCount = resultData.pagination.total_elements;
        this.paginationData.totalPage = resultData.pagination.total_page;
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
}
